import { Types } from '../config/types'


export interface FormData {
  [formFieldName: string]: FormDataItem;
}

export interface FormDataItem {
  required?: boolean | Types[]; // Always required or only for Types

  // formOrder?: number // Probably clearer if in Form components
  subPostOnly?: boolean; // Only enable field on subposts
}

export const formData: FormData = {
  type: {
    required: true,
  },
  cat: {
    required: true,
  },
  title: {
    required: true,
  },
  text: {
    required: false, // ['term', 'text', 'list', 'object', 'person', 'object', 'entity'],
  },
  longlat: {
    required: false,
  },
  ref: {
    subPostOnly: true,
  },


  // For either url or imgUrl
  urls: {
    // TODO: Enable on production?
    // required: ['media', 'person', 'object']
  },
}
