import {
  AutocompleteOwnerState,
  AutocompleteRenderOptionState,
  Box,
  GlobalStyles,
  Popper,
  autocompleteClasses,
  useMediaQuery,
} from '@mui/material'
import { Theme, alpha, styled, useTheme } from '@mui/material/styles'

import TypeIcon from '../components/Type/TypeIcon'
import CatIcon from '../components/Cat/CatIcon'

import { Cats } from '../constants/config/categories'
import { Types } from '../constants/config/types'

export const POPUP_MAX_WIDTH = 460

export const PUBLIC_COLOR = '#ffd700' // Is used on things that are still private, and can be published (private/shared)
export const SHARE_COLOR = 'green' // Is used on things that are still private, and can be shared (private)

export const POST_CARD_HEADER_MIN_HEIGHT = 160

export const DRAWER_WIDTH = 211

export const heroHeight = '25vh'
export const heroHeightLargeDesktop = '33vh'

export const headerHeight = 144
export const headerHeightLargeDesktop = headerHeight

export const topBarHeight = 64 // Check App.css if changing
export const bottomSpacing = 32
export const bottomBarHeight = topBarHeight

export const scrollbarWidth = 12
export const scrollbarHeight = 9

export const mainContainerMaxWidth = 'md'

export const bottomBarCutoff = 'md'

export const postBodyPadding = 1

export const defaultStyles = {
  transitionSlowest: '3s all',
  transitionSlower: '2s all',
  transitionSlow: '1s all',
  transitionSmooth: '0.8s all',
  transition: '0.6s all',
  transitionFast: '0.4s all',
  transitionFastest: '0.2s all',

  transitionBezierFast: '0.3s cubic-bezier(.47,1.64,.41,.8)',

  blurFilterRed: 'drop-shadow(1px 1px 12px red)',
}

export function getHeroDims(open: boolean): {
  height: string
  desktopHeight: string
} {
  if (open) {
    return {
      height: heroHeight,
      desktopHeight: heroHeightLargeDesktop,
    }
  } else {
    return {
      height: `calc(${heroHeight} /2)`,
      desktopHeight: `calc(${heroHeightLargeDesktop} /2)`,
    }
  }
}

export const useSearchBoxStyles = () => {
  const { palette } = useTheme()
  return {
    position: 'relative',
    borderRadius: '4px',
    backgroundColor: alpha(palette.text.secondary, 0.15),
    '&:hover': {
      backgroundColor: alpha(palette.text.secondary, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    sm: {
      // marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }
}

export function getBodyHeight(desktop?: boolean) {
  const heightMinusBottomBar = topBarHeight + bottomBarHeight
  return `calc(100vh - ${desktop ? topBarHeight : heightMinusBottomBar}px)`
}
export const useContainerStyles = () => {
  const theme = useTheme()
  return {
    rootSpacing: {
      paddingTop: `${topBarHeight}px`,
      paddingBottom: 0,
      minHeight: '100vh', // Fix blank background showing after @mui v6
      height: '100%', // Fix blank background showing after @mui v6
      [theme.breakpoints.down(bottomBarCutoff)]: {
        paddingBottom: `${bottomBarHeight}px`,
      },
    },
    root: {
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      paddingTop: theme.spacing(3),
      paddingBottom: `${bottomSpacing}px`,
      [theme.breakpoints.down(bottomBarCutoff)]: {
        paddingBottom: `${bottomBarHeight}px`,
      },
    },
    rootScroll: {
      // position: 'absolute',
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      overflowY: 'scroll',
      maxWidth: '100%',
      overflowX: 'hidden',
      maxHeight: `calc(100vh - ${topBarHeight}px)`,
      height: `calc(100vh - ${topBarHeight}px)`,
      paddingTop: theme.spacing(3),
      paddingBottom: `${bottomSpacing}px`,
      [theme.breakpoints.down(bottomBarCutoff)]: {
        paddingBottom: `${bottomBarHeight}px`,
      },
    },
    rootSpacingNoFlex: {
      overflowX: 'scroll',
      paddingBottom: `${bottomSpacing}px`,
      [theme.breakpoints.down(bottomBarCutoff)]: {
        paddingBottom: `${bottomBarHeight}px`,
      },
    },
    rootMargins: {
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
      width: '100%',
    },
    forcedHeight: {
      height: `calc(100vh - ${topBarHeight}px)`,
      [theme.breakpoints.down(bottomBarCutoff)]: {
        height: `calc(100vh - ${topBarHeight + bottomBarHeight}px)`,
      },
    },
  }
}
export const useBreakpoints = () => {
  const muiTheme = useTheme()

  // const matchesSmallest = useMediaQuery(muiTheme.breakpoints.down('sm'))
  // const matchesMobile = useMediaQuery(muiTheme.breakpoints.down('md'))
  // const matchesMedium = useMediaQuery(muiTheme.breakpoints.down('lg'))
  // const matchesLarge = useMediaQuery(muiTheme.breakpoints.down('xl'))
  // const matchesLargest = useMediaQuery(muiTheme.breakpoints.up('lg'))

  // const largerThanMobile = useMediaQuery(muiTheme.breakpoints.up('md'))
  // const largerThanMedium = useMediaQuery(muiTheme.breakpoints.up('lg'))
  // const largerThanLarge = useMediaQuery(muiTheme.breakpoints.up('xl'))

  const matchesSmallest = useMediaQuery(muiTheme.breakpoints.down('xs'))
  const matchesMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const matchesMedium = useMediaQuery(muiTheme.breakpoints.down('md'))
  const matchesLarge = useMediaQuery(muiTheme.breakpoints.down('lg'))
  const matchesLargest = useMediaQuery(muiTheme.breakpoints.up('xl'))

  const largerThanMobile = useMediaQuery(muiTheme.breakpoints.up('sm'))
  const largerThanMedium = useMediaQuery(muiTheme.breakpoints.up('md'))
  const largerThanLarge = useMediaQuery(muiTheme.breakpoints.up('lg'))

  return {
    matchesSmallest,
    matchesMobile,
    matchesMedium,
    matchesLarge,
    matchesLargest,
    largerThanMobile,
    largerThanMedium,
    largerThanLarge,
  }
}
export const useGridXs = (forceMobile?: boolean) => {
  const { matchesMobile, matchesMedium, matchesLarge, matchesLargest } =
    useBreakpoints()
  return matchesMobile || forceMobile
    ? 12
    : matchesMedium
      ? 6
      : matchesLarge
        ? 4
        : matchesLargest
          ? 2
          : 3
}

export const fabSpacing = {
  bottom: {
    xs: 16 + 56,
    sm: 16 + 56,
    md: 16,
    lg: 16,
  },
}

export const inputGlobalStyles = (themeObject: Theme) =>
  (
    <GlobalStyles
      styles={{
        '#root, body': {
          backgroundColor: themeObject.palette.background.paper,
        },
        '#root, html, body': {
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            backgroundColor: themeObject.palette.background.default,
          },
          '&::-webkit-scrollbar': {
            width: scrollbarWidth,
            height: scrollbarHeight,
            backgroundColor: themeObject.palette.background.default,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themeObject.palette.info.main,
          },
        },
        div: {
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            backgroundColor: themeObject.palette.background.paper,
          },
          '&::-webkit-scrollbar': {
            width: scrollbarWidth,
            height: scrollbarHeight,
            backgroundColor: themeObject.palette.background.paper,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themeObject.palette.info.main,
          },
        },
        ul: {
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            backgroundColor: themeObject.palette.background.paper,
          },
          '&::-webkit-scrollbar': {
            width: scrollbarWidth,
            height: scrollbarHeight,
            backgroundColor: themeObject.palette.background.paper,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themeObject.palette.info.main,
          },
        },
        '#recent-tags-body': {
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
        a: {
          color: themeObject.palette.primary.light,
        },
      }}
    />
  )

export const iconFontSize = (size: 'tiny' | 'mini' | 'large' | 'extra-large') => {
  switch (size) {
    case 'tiny':
      return '1.2rem'
    case 'mini':
      return '26px'

    case 'large':
      return '38px'

    case 'extra-large':
      return '48px'
    default:
      return '38px'
  }
}

export const ScrollPopper = styled(Popper)(({ theme }) =>
  ({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      minHeight: '330px',
      maxHeight: '70vh',
      zIndex: theme.zIndex.tooltip,
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        backgroundColor: theme.palette.background.default,
      },
      '&::-webkit-scrollbar': {
        width: scrollbarWidth / 2,
        height: scrollbarHeight / 2,
        backgroundColor: theme.palette.background.default,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.warning.main,
      },
      '& ul': {
        minHeight: '330px',
        padding: 0,
        margin: 0,
      },
    },
  }))

export const BasicScrollPopper = styled(Popper)(({ theme }) =>
  ({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      zIndex: theme.zIndex.tooltip,
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        backgroundColor: theme.palette.background.default,
      },
      '&::-webkit-scrollbar': {
        width: scrollbarWidth / 2,
        height: scrollbarHeight / 2,
        backgroundColor: theme.palette.background.default,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.warning.main,
      },
      '& ul': {
        padding: 0,
        margin: 0,
      },
    },
  }))

export const RenderOption = (
  props: React.HTMLAttributes<HTMLLIElement> & { key: string},
  option: string,
  state: AutocompleteRenderOptionState,
  ownerState: AutocompleteOwnerState<string, false, false, false, 'div'>,
) =>{
  const { key, ...rest } = props
  return (
    <Box
      key={`key-${key}`}
      sx={{
        mx: 1,
        my: 0.3,
        [`&.${autocompleteClasses.option}`]: {
          px: 1,
          py: 0.3,
        },
      }}
      component="li"
      {...rest}
    >
      {option ? ownerState.getOptionLabel(option) : <em>None</em>}
    </Box>
  )
}

export const RenderTypeOption = (
  props: React.HTMLAttributes<HTMLLIElement> & { key: string},
  option: string,
  state: AutocompleteRenderOptionState,
  ownerState: AutocompleteOwnerState<string, false, false, false, 'div'>,
) =>{
  const { key, ...rest } = props
  return (
    <Box
      key={`key-${key}`}
      sx={{
        mx: 1,
        my: 0.3,
        [`&.${autocompleteClasses.option}`]: {
          px: 1,
          py: 0.3,
        },
      }}
      component="li"
      {...rest}
    >
      <TypeIcon type={option as Types | ''} mini sx={{ mr: 1 }} />
      {option ? ownerState.getOptionLabel(option) : <em>None</em>}
    </Box>
  )
}

export const RenderCatOption = (
  props: React.HTMLAttributes<HTMLLIElement> & { key: string},
  option: string,
  state: AutocompleteRenderOptionState,
  ownerState: AutocompleteOwnerState<string, false, false, false, 'div'>,
) =>{
  const { key, ...rest } = props
  return (
    <Box
      key={`key-${key}`}
      sx={{
        mx: 1,
        my: 0.3,
        [`&.${autocompleteClasses.option}`]: {
          px: 1,
          py: 0.3,
        },
      }}
      component="li"
      {...rest}
    >
      <CatIcon cat={option as Cats} mini sx={{ mr: 1 }} />
      {option ? ownerState.getOptionLabel(option) : <em>None</em>}
    </Box>
  )
}
