import categories, { CatData, Cats } from '../constants/config/categories'
import { Types, allTypes, defaultFieldData } from '../constants/config/types'
import { CONFIG } from '../constants/config/CONFIG'

import { FormDataItem, formData } from '../constants/types/FormData'

import { capitalizeFirstLetter } from './textFunc'


// TODO: Write tests
// TODO: Use lodash

export function getTypeItem(typeQuery?: string) {
  const typeItem = allTypes.find((t) =>
    t.val === typeQuery)
  if (typeItem) {
    return typeItem
  } else {
    return null
  }
}

export function getCatItem(catQuery?: string): CatData | null {
  const dat = [...categories]
  const catItem = dat.find((t) =>
    t.val === catQuery)
  if (catItem) {
    return catItem
  } else {
    return null
  }
}

export function shortTypeString(
  type: Types | '',
  noPlural?: boolean,
): string {
  const typeItem = getTypeItem(type)
  const typeTit = typeItem
    ? typeItem.plural
      ? noPlural
        ? typeItem.val
        : typeItem.plural
      : `${typeItem.val}${noPlural ? '' : 's'}`
    : CONFIG.things
  return typeTit
}

export function singularSubTypeString(typeQuery: string): string {
  const typeItem = getTypeItem(typeQuery)
  const typeStart = typeItem
    ? typeItem.singular
      ? typeItem.singular
      : 'a'
    : ''
  return `${typeStart} ${typeItem ? capitalizeFirstLetter(typeItem.val) : CONFIG.things
  }`
}

interface TypeTitleParams {
  type?: Types | ''
  cat?: Cats | ''
  tags?: string[]
  url?: string
  noCap?: boolean
  noS?: boolean
  userId?: string
  sourceId?: string
  vis?: string
}

export function typeTitle({
  type = '',
  cat = '',
  tags = [],
  url = '',
  noCap = false,
  noS = false,
  userId = '',
  sourceId = '',
  vis = '',
}: TypeTitleParams): string {
  const shortType = shortTypeString(type, noS)

  const categoryItem = getCatItem(cat)
  const categoryValue = categoryItem?.modifier ?? categoryItem?.val ?? ''
  const firstTag = tags[0] as string

  const titleArray = [
    categoryValue,
    firstTag,
    shortType
  ]

  const finalTitle = titleArray
    .filter(Boolean)
    .map((word) =>
      word.trim())
    .join(' ')
    .trim()

  const who =
    vis === 'public'
      ? 'public'
      : vis === 'private'
        ? 'private'
        : vis === 'shared'
          ? 'shared'
          : vis === 'visible'
            ? 'visible'
            : ''

  const formattedTitle = noCap
    ? finalTitle.trim()
    : capitalizeFirstLetter(who
      ? `${userId ? 'your' : ''} ${who} ${url ? 'links' : finalTitle}`.trim()
      : sourceId
        ? `SourceId: ${sourceId}`
        : finalTitle).trim()

  return formattedTitle
}

export const getFormData = (fieldName: string): FormDataItem | null => {
  const fieldRef = formData[fieldName] ? formData[fieldName] : null
  if (fieldRef !== null) {
    // @ts-ignore
    return fieldRef
  } else {
    // console.error('Invalid fieldname: ', fieldName)
    return null
  }
}

export const getFormDataField = (
  fieldValue: string,
  fieldName: string
): boolean | string => {
  const fieldRef = getFormData(fieldName)
  if (fieldRef !== null && fieldRef[fieldValue]) {
    return fieldRef[fieldValue]
  } else {
    // console.error('Invalid fieldValue or fieldName: ', fieldValue, fieldName)
    return false
  }
}

export const isFieldRequired = (fieldName: string, type?: Types): boolean => {
  const requiredField = getFormDataField('required', fieldName)
  if (requiredField !== null && type) {
    const required =
      typeof requiredField === 'boolean'
        ? requiredField
        : requiredField.indexOf(type) !== -1
    return required
  } else {
    // console.error('Invalid fieldname: ', fieldName)
    return false
  }
}

export function getTypeItemProp(fieldProp: string, type?: string): string {
  const typeItem = getTypeItem(type)
  if (typeItem && typeItem[fieldProp]) {
    return typeItem[fieldProp]
  } else {
    // console.error('Invalid fieldProp: ', fieldProp)
    return ''
  }
}

// 'Term' === getDefaultValue('title', 'term')
// 'place' === getDefaultValue('cat', 'person')
export const getTypeField = (fieldName: string, type?: string): string => {
  const typeFieldsRef = getTypeItemProp('fields', type)

  if (typeFieldsRef && typeFieldsRef[fieldName]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const typeField = typeFieldsRef ? typeFieldsRef[fieldName] : ''
    return typeField
  } else {
    // console.error('Invalid fieldName: ', fieldName)
    return ''
  }
}

export const getTypeFieldValue = (
  fieldValue: string,
  fieldName: string,
  type?: string,
): string => {
  const typeField = getTypeField(fieldName, type)
  if (typeField && typeField[fieldValue]) {
    return typeField[fieldValue]
  } else {
    // console.error('Invalid fieldName: ', fieldName)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return defaultFieldData?.[fieldName]?.[fieldValue] || ''
  }
}

export function typeCatPrefix({
  type = '',
  cat = '',
}: TypeTitleParams): string {
  const shortType = shortTypeString(type, true)

  const categoryItem = getCatItem(cat)
  const categoryValue = categoryItem?.modifier ?? categoryItem?.val ?? ''

  const titleArray = [categoryValue, shortType]

  const finalTitle = titleArray
    .filter(Boolean)
    .map((word) =>
      word.trim())
    .join(' ')

  const formattedTitle = capitalizeFirstLetter(finalTitle)

  return formattedTitle
}
