export const CONFIG = {
  brandName: process.env.VITE_TITLE || 'Default Name',

  app: process.env.VITE_SHORT_TITLE || 'default',

  home: process.env.VITE_HOME || 'r', // Root of namespace urls: `/r/path/to/thing/`. Only valid chars allowed, could be %25 if you'd want. Check if not already defined in routeVars when changing!! Default is normally also / so you don't need prefix... (only ALL_ROUTES are not available as namespaces)
  thing: process.env.VITE_THINGS || 'thing', // Blank state
  things: process.env.VITE_THINGS || 'stuff', // Blank state plural
  post: process.env.VITE_POST || 'post', // Name of singular post
  posts: process.env.VITE_POSTS || 'posts', // Name of plural post

  primaryColor: process.env.VITE_WEBSITE_THEME_COLOR || '#4092ce',
  primaryColorDark: process.env.VITE_WEBSITE_THEME_COLOR_DARK || '#a31545',

  secondaryColor: process.env.VITE_WEBSITE_THEME_COLOR_SECONDARY || '#f7a345',
  secondaryColorDark:
    process.env.VITE_WEBSITE_THEME_COLOR_SECONDARY_DARK || '#339ba5',

  successColor: process.env.VITE_WEBSITE_SUCCESS_COLOR || '#00FF00',
  successColorDark: process.env.VITE_WEBSITE_SUCCESS_COLOR_DARK || '#00FF00',

  infoColor: process.env.VITE_WEBSITE_INFO_COLOR || '#82a6c2',
  infoColorDark: process.env.VITE_WEBSITE_INFO_COLOR_DARK || '#82a6c2',

  warningColor: process.env.VITE_WEBSITE_WARNING_COLOR || '#bcaa47',
  warningColorDark: process.env.VITE_WEBSITE_WARNING_COLOR_DARK || '#bcaa47',

  errorColor: process.env.VITE_WEBSITE_ERROR_COLOR || '#d32f2f',
  errorColorDark: process.env.VITE_WEBSITE_ERROR_COLOR_DARK || '#d32f2f',

  urlServiceName: 'Brave',
  urlService: 'https://search.brave.com/',

  imageServiceName: 'Brave',
  imageService: 'https://search.brave.com/',

  archiveSearchName: 'InternetArchive',
  archiveSearch: 'https://web.archive.org/web/*/%s',

  rootDomain: process.env.VITE_ROOT_DOMAIN || 'localhost:3000',
  dashboardDomain: process.env.VITE_DASHBOARD_DOMAIN || 'localhost:3000',
  websiteName: process.env.VITE_WEBSITE_NAME || 'localhost',
}
