import { useEffect, useState } from 'react'

import {
  ExistingPostData,
  FirebaseDate,
  SubPostData,
} from '../constants/post-data'
import { UriPostById } from '../firebase/uri'

function FromNow(
  date: Date | string,
  nowDate: Date | number = Date.now(),
) {
  // console.log(`🚀 ~ date:`, date)
  // console.log(`🚀 ~ nowDate:`, nowDate)
  const SECOND = 1000
  const MINUTE = 60 * SECOND
  const HOUR = 60 * MINUTE
  const DAY = 24 * HOUR
  const WEEK = 7 * DAY
  const MONTH = 30 * DAY
  const YEAR = 365 * DAY

  const now = typeof nowDate === 'object' ? nowDate : new Date(nowDate)
  const dateObj = typeof date === 'object' ? date : new Date(date)

  const diff = now.getTime() - dateObj.getTime()
  const diffAbs = Math.abs(diff)

  if (diffAbs < SECOND) {
    return 'just now'
  }

  const intervals = [
    { ge: YEAR, divisor: YEAR, unit: 'year' },
    { ge: MONTH, divisor: MONTH, unit: 'month' },
    { ge: WEEK, divisor: WEEK, unit: 'week' },
    { ge: DAY, divisor: DAY, unit: 'day' },
    { ge: HOUR, divisor: HOUR, unit: 'hour' },
    { ge: MINUTE, divisor: MINUTE, unit: 'minute' },
    { ge: SECOND, divisor: SECOND, unit: 'second' },
  ]

  for (const interval of intervals) {
    if (diffAbs >= interval.ge) {
      const x = Math.round(diff / interval.divisor)
      const isFuture = diff < 0
      return new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' }).format(
        isFuture ? x : -x,
        // @ts-expect-error unit
        interval.unit,
      )
    }
  }

  return ''
}

function DateRenderer({ date }: { date: Date }) {
  const [currentTime, setCurrentTime] = useState(FromNow(date))

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(FromNow(date))
    }, 60000) // update every minute

    return () =>
      clearInterval(interval)
  }, [date])

  return currentTime
}

const dateStr = (date?: Date) => {
  if (date) {
    return date.toLocaleDateString('en-gb', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
  } else {
    return 'unknown'
  }
}

const firestoreDate = (date?: FirebaseDate) => {
  const theDate = date?.toDate()
  if (theDate) {
    return theDate
  } else {
    return 'unknown'
  }
}

function DateString(
  post: Partial<ExistingPostData & SubPostData & UriPostById>,
  noPrefix?: boolean,
  fromNow?: boolean,
): string {
  const date = post?.published?.toDate
    ? post?.published.toDate()
    : post?.edited?.toDate
      ? post?.edited.toDate()
      : post?.added?.toDate
        ? post?.added.toDate()
        : post?.created?.toDate
          ? post?.created.toDate()
          : ''

  const rawDate =
    post?.published || post?.created || post?.edited || post?.added

  const displayDate = date ? dateStr(date) : 'error' //dateStr(rawDate)

  const dateType = post?.published
    ? 'Published'
    : post?.edited
      ? 'Edited'
      : post?.added
        ? 'Added'
        : post?.created
          ? 'Created'
          : 'Appeared'

  const finalDateType = noPrefix ? '' : `${dateType}${fromNow ? '' : ': '}`

  return fromNow
    ? `${finalDateType} ${FromNow(date)}`
    : `${finalDateType}${displayDate}`
}

export function createdOrEditedDate(
  post: Partial<ExistingPostData | SubPostData>,
  showEdited?: boolean,
  noPrefix?: boolean,
  fromNow?: boolean,
): string {
  const date = showEdited
    ? (post?.edited?.toDate && post?.edited.toDate()) || ''
    : post?.created?.toDate
      ? post?.created.toDate()
      : ''

  const displayDate = date ? dateStr(date) : 'error' //dateStr(rawDate)

  const dateType = showEdited
    ? post?.edited && 'Edited'
    : post?.created
      ? 'Created'
      : 'Appeared'

  const finalDateType = noPrefix ? '' : `${dateType!}${fromNow ? '' : ': '}`

  return fromNow
    ? `${finalDateType} ${FromNow(date)}`
    : `${finalDateType}${displayDate}`
}

function dateStringsFromPost(post: Partial<ExistingPostData & UriPostById>) {
  const published = firestoreDate(post?.published)
  const created = firestoreDate(post?.created)
  const edited = firestoreDate(post?.edited)
  const added = firestoreDate(post?.added)
  return {
    published,
    created,
    edited,
    added,
  }
}

export function dateStringsFromNow(post: Partial<ExistingPostData & UriPostById>) {
  const dateStrings = dateStringsFromPost(post)
  const published = FromNow(dateStrings.published)
  const created = FromNow(dateStrings.created)
  const edited = FromNow(dateStrings.edited)
  const added = FromNow(dateStrings.added)
  return {
    published,
    created,
    edited,
    added,
  }
}

export function dateStringFromFirebaseDate(date: FirebaseDate): string {
  if (date) {
    const dater = date.toDate && date.toDate()
    const dat = new Date(dater).toLocaleDateString('en-gb', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    return dat ? `${dat}` : ''
  } else {
    return ''
  }
}

export function dateStringFromDate(date: Date): string {
  if (date) {
    const dat = date.toLocaleDateString('en-gb', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    return dat ? `${dat}` : ''
  } else {
    return ''
  }
}

export default DateString
