import type { ReactElement } from 'react'

import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'

import { topBarHeight } from '@Core/styles/globalStyles'

export function FullscreenLoading(): ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: `calc(100vh - ${topBarHeight}px)`,
        width: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  )
}
