'use client'

import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { SxProps } from '@mui/system'

import { Cats, allCats } from '../../constants/config/categories'
import { iconFontSize } from '../../styles/globalStyles'
import { capitalizeFirstLetter } from '../../utils/textFunc'

export default function CatIcon({
  cat,
  mini,
  tiny,
  extraLarge,
  color = 'inherit',
  edit,
  showTooltip,
  toolTipText,
  goCat,
  onContextMenu,
  sx,
}: {
  cat: Cats
  color?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  edit?: boolean
  mini?: boolean
  tiny?: boolean
  extraLarge?: boolean
  showTooltip?: boolean
  toolTipText?: string
  goCat?: (
    type: Cats,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onContextMenu?: (
    type: Cats,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  sx?: SxProps
}) {
  const getCatName = () =>
    (
      cat &&
      allCats.find((cate) => {
        const catVal = cat
        const resultFound = cate.val === catVal
        if (resultFound) {
          return resultFound
        } else {
          return false
        }
      })
    )
  const catName = getCatName()

  const catIcon = catName && catName.icon ? catName.icon : 'view_comfy'

  const defaultToolTipTxt = showTooltip
    ? catName
      ? `Category: '${catName.val}'`
      : 'Click to select type'
    : `${capitalizeFirstLetter(cat)}`

  const toolTipTxt = toolTipText ? toolTipText : defaultToolTipTxt

  const fontSize = tiny
    ? 'tiny'
    : mini
      ? 'mini'
      : extraLarge
        ? 'extra-large'
        : 'large'

  const icon = (
    <Icon
      color={color}
      style={{
        fontSize: iconFontSize(fontSize),
      }}
      sx={sx}
    >
      {catIcon}
    </Icon>
  )

  if (goCat) {
    return (
      <IconButton
        onClick={
          !edit
            ? (e) => {
                e.stopPropagation()
                goCat(cat, e)
              }
            : undefined
        }
        onContextMenu={(e) => {
          if (onContextMenu) {
            e.preventDefault()
            onContextMenu(cat, e)
          }
        }}
        disabled={edit}
        sx={sx}
      >
        <Tooltip title={toolTipTxt}>{icon}</Tooltip>
      </IconButton>
    )
  } else if (showTooltip || toolTipTxt) {
    return <Tooltip title={toolTipTxt}>{icon}</Tooltip>
  } else {
    return icon
  }
}
