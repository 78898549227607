'use client'

import { SxProps } from '@mui/material/styles'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { Types, allTypes } from '../../constants/config/types'
import { CONFIG } from '../../constants/config/CONFIG'
import { TypeData } from '../../constants/types/TypeData'

import { defaultStyles, iconFontSize } from '../../styles/globalStyles'

import { capitalizeFirstLetter } from '../../utils/textFunc'
import { shortTypeString } from '../../utils/typeFunc'

export default function TypeIcon({
  type,
  mini,
  tiny,
  color = 'inherit',
  disabled,

  goType,
  showTooltip,
  toolTipText,
  sx,
}: {
  type: Types | ''
  mini?: boolean
  tiny?: boolean
  color?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'publish'
    | 'share'
  disabled?: boolean
  showTooltip?: boolean
  toolTipText?: string
  goType?: (type: Types | '', e) => void
  sx?: SxProps
}) {
  function getTypeName(allT: TypeData[]) {
    return type && allT && allT.find((t) =>
      t.val === type)
  }

  const typeName = getTypeName(allTypes) || {
    icon: 'apps',
    val: CONFIG.things,
  }
  const typeIcon = typeName && typeName.icon

  const defaultToolTipTxt = showTooltip
    ? typeName
      ? `Type: '${typeName.val}'`
      : 'Click to select type'
    : `${capitalizeFirstLetter(shortTypeString(type, true))}`

  const toolTipTxt = toolTipText ? toolTipText : defaultToolTipTxt

  const fontSize = tiny ? 'tiny' : mini ? 'mini' : 'large'

  const icon = (
    <Icon
      style={{
        fontSize: iconFontSize(fontSize),
      }}
      color={disabled ? 'disabled' : color ? color : 'action'}
      sx={{ transition: defaultStyles.transitionFastest, ...sx }}
    >
      {typeIcon}
    </Icon>
  )

  const iconWithTooltip = <Tooltip title={toolTipTxt}>{icon}</Tooltip>

  if (goType) {
    return (
      <IconButton
        onClick={
          !disabled
            ? (e) => {
                e.stopPropagation()
                goType(type, e)
              }
            : undefined
        }
        disabled={disabled}
        sx={{ transition: defaultStyles.transitionFastest, ...sx }}
        size={tiny ? 'small' : mini ? 'medium' : 'large'}
      >
        {showTooltip || toolTipText ? iconWithTooltip : icon}
      </IconButton>
    )
  } else if (showTooltip || toolTipTxt) {
    return iconWithTooltip
  } else {
    return icon
  }
}
