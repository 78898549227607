import { Edge, Node } from '@xyflow/react'

import { ObjectEditorValue } from '../types/object'
import { TypeData } from '../types/TypeData'
import { CONFIG } from './CONFIG'

// export type ThingsType = typeof CONFIG.things // Things represents blank type state (not the same as '', where type is not set)

export type ComponentPostFields =
  // Most fields have special components for them, like input fields or maps
  //
  // Required:
  //
  // Ids (No Edit components exist)
  | 'id' // Identicon
  | 'userId' // Profile
  //
  // Body
  //
  // Minimal & required:
  //
  | 'title' // Title
  | 'type' // Type
  | 'cat' // Category
  //
  // Optional:
  //
  | 'text' // Paragraph(s)
  | 'url' // Favicon
  | 'imgUrl' // Image
  //
  //
  // Meta
  //
  // Tags
  | 'tags' // Tags
  | 'tagsPublished' // Date
  //
  // Object & Media
  | 'object' // ObjectEditor
  | 'flow' // FlowMap
  | 'mediaLength' // Time in seconds
  //
  // Source
  | 'isSource' // Is Post mean to act as source (posts with urls default to true)
  //
  // Urls
  | 'urls' // SubPost urls
  | 'uriRef' // Public Uri ref id
  //
  // Public/Shared
  | 'visiblity' // private, shared, public
  | 'namespace' // NamespaceChip
  | 'ref' // RefComponent - namespace id
  | 'refId' // RefComponent - post id
  //
  // Source
  | 'sourceId' // SourceDataComponent
  | 'sourceType' // SourceDataComponent
  //
  // SubPosts
  | 'postCount' // Count
  | 'subPostAdded' // Date: Subpost was last added
  | 'subPostEdited' // Date: Subpost was last edited
  // Connections
  | 'connectionCount' // Count
  | 'connectionAdded' // Date
  //
  // Location
  | 'longlat' // MapComponent
  | 'geoHash' // Filter Posts
  | 'geoPriority'
  //
  | 'ext' //Version: ext >= 1 ? Added with Extension : Added in web/mobile
  //
  // Dates
  | 'created'
  | 'edited'
  | 'added' // DEPRECATED: replaced by created
  | 'published'
  | 'unpublished'
  | 'shared'
  | 'unshared'

  // To do:
  | 'series' // Tags?
  | 'data' // Graph
  | 'date' // Date
  | 'parentId' // Upgraded suposts
  | 'subPostId' // Upgraded suposts

  // Misc
  // | 'bio'
  // | 'connection'
  | 'value'

type FieldType = 'string' | 'text' | 'array' | 'object'

export interface PostField {
  required?: boolean
  maxLength?: number
  alias?: string
  optional?: boolean
  hint?: string
  enabled?: boolean
  defaultValue?: string | string[] // Meant for autofilling cat only
  fieldType?: FieldType
  useAdjective?: boolean
  options?: string[]
  component?: any
}

// Fields that are manually modifyable by user (so not userId, created, edited etc.)
export interface PostFormFields {
  title?: PostField // Always required
  text?: PostField // Almost always required?
  type?: PostField // Always required
  cat?: PostField // Always required, can be autofilled according to what type

  tags?: PostField
  url?: PostField
  imgUrl?: PostField

  object?: ObjectEditorValue
  flow?: { nodes: Node[]; edges: Edge[] } // {nodes: Node[], edges: Edge[]}

  geo?: PostField
  date?: PostField

  ref?: PostField
  refId?: PostField
  sourceId?: PostField
  sourceType?: PostField

  visibility?: PostField

  layout?: string
  disabledFields?: string[]
  subTypes?: string[] // Default types that can be added as subposts (not necessarily limited to)
}

export const defaultFieldData = {
  title: {
    description: `Title that encapsulates the ${CONFIG.thing}`,
  },
  text: {
    description: `Text that describes the ${CONFIG.thing}`,
  },
  type: {
    description: `The type of ${CONFIG.thing}`,
  },
  cat: {
    description: `The category that best fits the ${CONFIG.thing}`,
  },
  tags: {
    description: `Publicly visible tags to organize ${CONFIG.things}`,
  },
  url: {
    description: `Primary URL belonging to the ${CONFIG.thing}`,
  },
  imgUrl: {
    description: `Primary image URL for the ${CONFIG.thing}`,
  },
  object: {
    description: `Object containing 1-dimensional data of ${CONFIG.thing}`,
  },
  flow: {
    description: `Flowchart containing 2-dimensional data of ${CONFIG.thing}`,
  },
  location: {
    description: `Geographic location data of ${CONFIG.thing}`,
  },
}

export type Fields = keyof PostFormFields

// Types often have a primary related fieldname & fieldtype associated with it
export type PostTypes =
  // Title & Text
  | 'term' // title
  | 'text' // text
  | 'list' // array

  // Media
  | 'graph' // data
  | 'object' // object
  | 'media' // url / imgUrl

  // Points of interest
  | 'person' // sourceId & userId
  | 'entity' // domain/url & sourceId & userId
  | 'location' // longlat
  //

export type SecondaryPostTypes =
 // Create -> On publish upgrade to Post
  | 'quote'
  | 'article'
  | 'review'

  //
  | 'feed' // list
  | 'collection'
  | 'thread'

  // Time
  | 'date'
  | 'event'
  | 'period'

export type AdvancedPostTypes =
  | 'analysis'
  | 'thesis'
  | 'hypothesis'
  | 'research'
  | 'paper'

export type SubpostTypes =
  // Initiate
  | 'comment'
  | 'question'
  | 'statement'

  //
  | 'dialog'
  | 'opinion'
  | 'idea'

  // Responses
  | 'response'
  | 'answer'
  | 'reply'

  // Dynamic
  | 'process'
  | 'system'
  //
  | 'pattern'
  | 'method'

  //
  | 'concept'
  | 'action'
  | 'filter' // Used to compose filter

  // Relations
  | 'source' // Describes source url or sourceData
  | 'relation' // relation to Post
  | 'ref' // reference to Post, should contain ref or refId
  //
  | 'group' // Describes group

  // Url
  | 'domain' // Describes url domain - sourceType
  | 'subdomain' // Describes url subdomain
  //
  | 'parameter' // Describes url parameter property
  | 'path' // Describes url path
  | 'anchor' // Describes url anchor

  // Judgements
  //
  | 'argue'
  | 'agree'
  | 'disagree'
  //
  | 'claim'
  | 'dispute'

  //
  | 'deduction'
  //
  | 'rule'

export type BasicTypes =

  // Simple singular
  // | 'boolean'
  | 'value'
  | 'string'
  // | 'number'

  // Simple multiple
  | 'array'
  // | 'set'
  | 'segment'
  | 'prose'
  | 'texture'
  // Time
  // | 'time'
  // | 'timestamp' // timestamp & timestampEnd
  // | 'duration'

  // Dimensional
  // | 'node'
  // | 'edge'
  // | 'metric'

  // State
  // | 'status'

  // Meta
  | 'link'
  | 'connection' // connection to Post - tags
  | 'source'
  // | 'identifier'
  // | 'userId'
  // | 'uri'

export type ComplexTypes =
  // Top level
  | 'view'
  | 'layer'
  | 'context'

  | 'document'
  | 'data'
  // | 'tree'
  // | 'map'
  // | 'table'
  // Dimensional
  // | 'vertex'
  // | 'vector'

export type SystemTypes =
  | 'post' // Used in PostObject
  | 'subpost' // Used in PostObject

  // Operations
  | 'suggestion'
  | 'edit'


// SubpostTypes can become normal post types, but start out as subPosts. The main 9 Types are the general default Types for top-level Posts.
// Basic types are generally not created manually as Posts or SubPosts but are more atomic system Types.
// There is a bit of Type-ception like the 'post' BasicType is used in the ObjectEditor as a reference to another post within a Post object, so it's not necessarily rendered the same as a reffed SubPost would render a Post.
export type Types = PostTypes | SecondaryPostTypes | SubpostTypes
| BasicTypes | ComplexTypes | SystemTypes // | ThingsType;

export interface SecondaryTypeData extends TypeData {
  val: SecondaryPostTypes
}
export interface SubpostTypeData extends TypeData {
  val: SubpostTypes
}
export interface BasicTypeData extends TypeData {
  val: BasicTypes
}
export interface ComplexTypeData extends TypeData {
  val: ComplexTypes
}
export interface SystemTypeData extends TypeData {
  val: SystemTypes
}

// System types, don't change base vals
export const primaryTypeData: TypeData[] = [
  {
    val: 'term',
    adjective: 'termable',
    description: 'A short word, term, phrase, saying or sentence',
    icon: 'short_text',
    fields: {
      title: {
        alias: 'Term',
      },
      text: {
        alias: 'Description',
        // maxLength: 420
      },
      cat: {
        defaultValue: 'philosophy',
      },
    },
    variants: {
      word: {
        fields: ['title'],
      },
      quote: {
        fields: ['title'],
      },
      definition: {
        fields: ['title', 'text'],
      },
      thing: {
        fields: ['title', 'text'],
      },
    },
  },
  {
    val: 'list',
    adjective: 'listable',
    description:
      'Lists of anything like words, stories, events, people, places or objects, or whatever',
    icon: 'list',
    // icon: 'toc',
    fields: {
      title: {
        alias: 'List name',
      },
      text: {
        alias: 'List description',
      },
    },
    variants: {
      todo: {
        fields: ['title', 'text', 'series'],
      },
      feed: {
        fields: ['title', 'text', 'uri'],
      },
      events: {
        fields: ['title', 'text', 'datum'],
      },
    },
    shortcuts: ['value'], // To jump to next item automatically after 'enter'ing value
  },
  {
    val: 'text',
    adjective: 'textual',
    verb: 'write',
    description: 'Stories, thought-experiments separate paragraphs, things',
    icon: 'subject',
    fields: {
      title: {
        alias: 'Title',
      },
      text: {
        alias: 'Text',
      },
    },
    variants: {
      story: {
        fields: ['title', 'text', 'series'],
      },
      paragraph: {
        fields: ['series', 'text'],
      },
      page: {
        fields: ['title', 'text'],
      },
    },
  },

  {
    val: 'object',
    adjective: 'objective',
    // verb: 'create',
    description: 'Anything that can be described in objective terms',
    icon: 'data_object',
    fields: {
      title: {
        alias: 'Object name',
      },
      text: {
        alias: 'Object description',
      },
    },
    variants: {
      anything: {
        // Any varint possible, is equivalent to series / tag
        fields: [], // All fields are available
      },
    },
  },

  // Graphs, flowcharts
  {
    val: 'graph',
    adjective: 'graphable',
    description:
      'Graphs, timelines, charts, of present, future, or past events',
    icon: 'timeline',
    fields: {
      title: {
        alias: 'Graph name',
      },
      text: {
        alias: 'Graph description',
      },
    },
    variants: {
      flowchart: {
        fields: ['title', 'flow'],
      },
      timeline: {
        fields: ['title', 'date', 'values'],
      },
      chart: {
        fields: ['title', 'text', 'datum'],
      },
      datum: {
        fields: ['title', 'datum'],
      },
      xyz: {
        fields: ['title', 'xyz'],
      },
    },
    // icon: 'show_chart',
    // icon: 'schema',
  },

  {
    val: 'media',
    plural: 'media',
    adjective: 'playable',
    description:
      'Media can be any playable thing from videos, to games, to other multimedia',
    icon: 'category',
    fields: {
      title: {
        alias: 'Media title',
      },
      text: {
        alias: 'Media description',
      },
      cat: {
        // defaultValue: 'music'
      },
    },
    variants: {
      anything: {
        // Any varint possible, is equivalent to series / tag
        fields: [], // All fields are available
      },
    },
  },

  {
    val: 'person',
    plural: 'people',
    adjective: 'personal',
    description: 'People, fictional or real, alive or dead, born or unborn',
    icon: 'self_improvement',
    // icon: 'emoji_people',
    // icon: 'local_library',
    fields: {
      title: {
        alias: 'Name',
      },
      text: {
        alias: 'Biography',
      },
      cat: {
        defaultValue: 'people',
      },
      url: {
        useAdjective: true,
      },
    },
    variants: {
      bio: {
        fields: ['title', 'text', 'uri'],
      },
      autobio: {
        fields: ['title', 'text', 'uri', 'uid'], // hidden uid field to verify it's user
      },
    },
  },

  {
    val: 'entity',
    plural: 'entities',
    description:
      'Any organised entity like a businesses, corporation, governments, clubs or groups',
    icon: 'corporate_fare',
    // icon: 'store',
    fields: {
      title: {
        alias: 'Entity name',
      },
      text: {
        alias: 'Entity function',
      },
      cat: {
        defaultValue: 'organisation',
      },
    },
    variants: {
      anything: {
        // Any varint possible, is equivalent to series / tag
        fields: [], // All fields are available
      },
    },
  },

  {
    val: 'location',
    adjective: 'locatable',
    verb: 'locate',
    description:
      'Places like bars, restaurants, meeting spots, clubs, squares, businesses and emergency services',
    icon: 'navigation',
    // icon: 'explore',
    fields: {
      title: {
        alias: 'Location name',
      },
      text: {
        alias: 'Location description',
      },
      cat: {
        defaultValue: 'place',
      },
    },
    variants: {
      country: {
        fields: ['title', 'text', 'geo'],
      },
      coordinates: {
        fields: ['title', 'text', 'xyz'],
      },
      xyz: {
        fields: ['title', 'xyz'],
      },
    },
  },

  // {
  //   val: 'flow',
  //   description: 'Flowchart of stuff',
  //   icon: 'account_tree',
  // },

  // {
  //   val: 'knowledge',
  //   description: 'Knowledges of things',
  //   icon: 'local_library',
  //   disabled: true
  // },
  // {
  //   val: 'Wisdom',
  //   description: 'Wisdoms of things',
  //   icon: 'self_improvement',
  //   disabled: true
  // },
]

export const secondaryTypeData: SecondaryTypeData[] = [
  {
    val: 'quote',
    description: 'A Quote',
    icon: 'format_quote',
    fields: {
      title: {
        alias: 'Quote',
      },
      text: {
        alias: 'Context or Origins',
      },
    },
  },
  {
    val: 'article',
    verb: 'author',
    description: 'An article about anything',
    icon: 'article',
    fields: {
      title: {
        alias: 'Article title',
      },
      text: {
        alias: 'Article body',
        fieldType: 'text',
      },
    },
  },
  {
    val: 'review',
    description: 'Review of things',
    icon: 'reviews',
    fields: {
      title: {
        alias: 'Review title',
      },
      text: {
        alias: 'Review',
      },
    },
  },

  //
  {
    val: 'feed',
    description: 'Feeds of things',
    icon: 'auto_awesome_mosaic',
    fields: {
      title: {
        alias: 'Feed name',
      },
      text: {
        alias: 'Feed description',
      },
    },
  },
  {
    val: 'collection',
    verb: 'collect',
    description: 'Collections can contain all kinds of things',
    icon: 'widgets',
    fields: {
      title: {
        alias: 'Collection name',
      },
      text: {
        alias: 'Description',
      },
    },
  },
  {
    val: 'thread',
    description: 'A thread of posts',
    icon: 'account_tree',
    fields: {
      title: {
        alias: 'Thread Name',
      },
      text: {
        // alias: 'Description',
        fieldType: 'string',
      },
    },
  },

  // Time
  {
    val: 'date',
    description: 'A date',
    icon: 'calendar_today',
    fields: {
      title: {
        alias: 'Date Name',
      },
      text: {
        alias: 'Value',
        fieldType: 'string',
      },
    },
  },
  {
    val: 'event',
    verb: 'plan',
    singular: 'an',
    description: 'Can be future, present, or historical events',
    icon: 'event',
    // disabled: true,
    fields: {
      title: {
        alias: 'Event name',
      },
      text: {
        alias: 'Description',
      },
      date: {
        alias: 'Date',
      },
    },
  },
  {
    val: 'period',
    singular: 'an',
    description: 'A period of time, can be day, week, month, year',
    icon: 'date_range',
    // disabled: true,
    fields: {
      title: {
        alias: 'Period',
      },
      text: {
        alias: 'Description',
      },
    },
  },
]

export const subTypeData: SubpostTypeData[] = [
  {
    val: 'comment',
    description: 'Comment on something',
    icon: 'chat_bubble',
    fields: {
      title: {
        alias: 'Comment',
      },
      text: {
        alias: 'Details',
        optional: true,
      },
    },
  },
  {
    val: 'question',
    verb: 'ask',
    description: 'Ask a question about anything',
    icon: 'live_help',
    fields: {
      title: {
        alias: 'Question',
      },
      text: {
        alias: 'Context',
      },
    },
  },
  {
    val: 'statement',
    verb: 'ask',
    description: 'Ask a question about anything',
    icon: 'assistant',
    fields: {
      title: {
        alias: 'Statement',
      },
      text: {
        alias: 'Context',
      },
    },
  },

  //
  {
    val: 'dialog',
    verb: 'talk',
    description: 'Start a dialog',
    icon: 'forum',
    fields: {
      title: {
        alias: 'Dialog subject',
      },
      text: {
        alias: 'Subject',
      },
    },
  },
  {
    val: 'opinion',
    description: 'Opinion on something',
    icon: 'sms',
    fields: {
      title: {
        alias: 'Opinion',
      },
      text: {
        alias: 'Details',
        optional: true,
      },
    },
  },
  {
    val: 'idea',
    description: 'An idea',
    icon: 'mms',
    fields: {
      title: {
        alias: 'Opinion',
      },
      text: {
        alias: 'Details',
        optional: true,
      },
    },
  },

  //
  {
    val: 'response',
    verb: 'respond',
    description: 'A response to something',
    icon: 'try',
    fields: {
      title: {
        alias: 'Response',
      },
      text: {
        alias: 'Details',
        optional: true,
      },
    },
  },
  {
    val: 'answer',
    description: 'An answer to a question',
    icon: 'feedback',
    fields: {
      title: {
        alias: 'Short Answer',
      },
      text: {
        alias: 'Long Answer',
        fieldType: 'text',
      },
      ref: {
        alias: 'Namespace with answer',
      },
      refId: {
        alias: 'Post Id with answer',
      },
    },
  },
  {
    val: 'reply',
    description: 'A reply to something',
    icon: 'chat',
    fields: {
      title: {
        alias: 'Short Reply',
      },
      text: {
        alias: 'Long Reply',
        fieldType: 'text',
      },
      ref: {
        alias: 'Namespace with reply',
      },
      refId: {
        alias: 'Post Id with reply',
      },
    },
  },

]

// Non-user selectable basic system types
export const basicTypeData: BasicTypeData[] = [
  {
    val: 'value',
    description: 'A generic value',
    icon: 'text_snippet',
    fields: {
      title: {
        alias: 'Value Name',
      },
      text: {
        required: true,
        alias: 'Value',
        fieldType: 'string',
      },
    },
  },
  {
    val: 'array',
    description: 'An array of items',
    icon: 'data_array',
    fields: {
      title: {
        alias: 'Array Name',
      },
      text: {
        // alias: 'Description',
        fieldType: 'string',
      },
    },
  },
  {
    val: 'string',
    description: 'String of text',
    icon: 'abc',
    fields: {
      title: {
        alias: 'Array Name',
      },
      text: {
        // alias: 'Description',
        fieldType: 'string',
      },
    },
  },

  {
    val: 'segment',
    description: 'Segment',
    icon: 'segment',
    fields: {
      title: {
        alias: 'Segment Name',
      },
      text: {
        // alias: 'Description',
        fieldType: 'string',
      },
    },
  },
  {
    val: 'prose',
    description: 'Some prosaic',
    icon: 'notes',
    fields: {
      title: {
        alias: 'Prose Name',
      },
      text: {
        // alias: 'Description',
        fieldType: 'string',
      },
    },
  },
  {
    val: 'texture',
    description: 'Texture',
    icon: 'texture',
    fields: {
      title: {
        alias: 'Texture Name',
      },
      text: {
        // alias: 'Description',
        fieldType: 'string',
      },
    },
  },


  {
    val: 'link',
    description: 'A url',
    icon: 'link',
  },
  {
    val: 'connection',
    verb: 'connect',
    description: 'Connections of things',
    icon: 'cable',
    fields: {
      title: {
        alias: 'Connection',
        fieldType: 'string',
      },
      text: {
        alias: 'Connection context',
      },
      subTypes: ['reference'],
    },
  },

  {
    val: 'source',
    description: 'A source',
    icon: 'source',
  },

]

export const complexTypeData: ComplexTypeData[] = [
  {
    val: 'view',
    adjective: 'viewable',
    description:
      'Views can be anything from viewpoints to portals, to any composition of content',
    icon: 'panorama_wide_angle',
    fields: {
      title: {
        alias: 'View name',
      },
      text: {
        alias: 'View description',
      },
    },
  },
  {
    val: 'layer',
    description: 'A layer',
    icon: 'layers',
    fields: {
      title: {
        alias: 'Layer Name',
      },
      text: {
        // alias: 'Description',
      },
    },
  },

  {
    val: 'context',
    description: 'Contextual data',
    icon: 'filter_tilt_shift',
    fields: {
      title: {
        alias: 'Context Name',
      },
      text: {
        // alias: 'Description',
      },
      ref: {
        alias: 'Namespace with context',
      },
      refId: {
        alias: 'Post Id with context',
      },
    },
  },
  //

  {
    val: 'document',
    description: 'Document',
    icon: 'insert_drive_file',
    fields: {
      title: {
        alias: 'Document Title',
      },
      text: {
        alias: 'Document Information',
        fieldType: 'string',
      },
    },
  },
  {
    val: 'data',
    description: 'Data',
    icon: 'dataset',
    fields: {
      title: {
        alias: 'Data Name',
      },
      text: {
        alias: 'Data',
        fieldType: 'string',
      },
    },
  },
  // {
  //   val: 'symbol',
  //   description: 'Symbols of things',
  //   icon: 'help_outline',
  //   // icon: 'bar_chart',
  //   disabled: true
  // },
  // {
  //   val: 'vertex',
  //   description: 'A 3d vertex',
  //   icon: 'hexagon',
  //   disabled: true,
  // },
  // {
  //   val: 'vector',
  //   description: 'A vector, a direction.',
  //   icon: 'polyline',
  //   fields: {
  //     title: {
  //       alias: 'Vector Name',
  //     },
  //     text: {
  //       // alias: 'Description',
  //       fieldType: 'string',
  //     },
  //   },
  // },
]

// Non-user selectable system types
export const systemTypeData: SystemTypeData[] = [
  {
    val: 'post',
    description: 'A post',
    icon: 'check_box_outline_blank',
    disabled: true,
  },
  {
    val: 'subpost',
    description: 'A sub-post',
    icon: 'stop',
    disabled: true,
  },
  {
    val: 'edit',
    description: 'An editorial adjustment',
    icon: 'edit_note',
  },
  {
    val: 'suggestion',
    verb: 'suggest',
    description: 'An suggestion for change',
    icon: 'difference',
  },

]

export const allTypes = [
  ...primaryTypeData,
  ...secondaryTypeData,
  ...subTypeData,
  ...basicTypeData,
  ...complexTypeData,
  ...systemTypeData,
]

export const allTypesArray = allTypes.map((type) =>
  type.val)
