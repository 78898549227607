import includes from 'lodash/includes'

import { checkTagCompatible } from './regExp'
// import nlp from 'compromise'

export const FORBIDDEN_CHARS = [
  '/',
  '\\',
  '?',
  '&',
  '=',
  '.',
  ',',
  '"',
  ':',
  '(',
  ')',
]

export const capitalizeFirstLetter = (value?: string): string => {
  if (value && typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1)
  } else {
    return value || ''
  }
}

export const removePlural = (str: string): string => {
  let modfiedString = str?.trim()
  if (str && typeof str === 'string') {
    modfiedString = str
      .replace(`'s`, ' ')
      .replace(`'s`, ' ')
      .replace(`'s`, ' ')
      .replace('`', '')
      .replace('`', '')
      .replace(`'`, '')
      .replace(`'`, '')
      .replace(`'`, '')
      .replace(`'`, '') // Bloody buggers
      .trim()
    return modfiedString
  } else {
    return ''
  }
}

export const stripCrapFromTitle = (title?: string): string => {
  // DODO: When tab title has - use left side as title, and right side as text
  // TODO: Regexify all this?
  // TODO: Strip tld name via url parameter?
  // TODO: Split title text in two parts: title & text, by heuristically finding a break in the title
  // TODO: Only trip when with - and at end of string, and is also yt domain
  let modfiedString = title?.trim()
  if (title && typeof title === 'string') {
    modfiedString = title
      // TODO: Only replace when at end, or when on websites that always append
      // TODO: Heuristic replace taking tld component when at end with dash
      // TODO: Only remove brand names when on the brandname domain
      .replace('- Twitch', '')
      .replace('- YouTube', '')
      .replace('- Wikipedia', '')
      .replace('- Wikipedia', '')
      .replace('- Wiktionary, the free dictionary', '')
      .replace('- IMDb', '')
      .replace('- Home', '')
      .replace('| Goodreads', '')
      .replace('Home -', '')
      // TODO: Only replace when at start or only on site that always prepends like youtube
      .replace(/\([^()]*\)/g, '') // Replace everything within parenthesis
      .split('|') // Replace pipes
      .join('-')
      .split('–') // Replace special dash character
      .join('-')
      .split('--')
      .join('-') // Don't allow consecutive dashes
      .trim()
    // TODO: Remove ({number}) from string!
    // str.replace('(1)', '')
    // str.replace(/[0-9\n]/g, '')
  }
  return modfiedString || ''
}

// const modifiedString = title
//   .replace(/\([^()]*\)/g, '') // Replace everything within parentheses
//   .replace(/\|/g, '-') // Replace pipes with dashes
//   .replace(/–/g, '-') // Replace special dash character with normal dash
//   .replace(/-{2,}/g, '-') // Remove consecutive dashes

export function startsWithCapital(word: string) {
  return /[A-Z]/.test(word.charAt(0))
}

export function startsWithCapitalOnly(word: string) {
  // Tests if word only has capital, and the rest is small
  const startsWithCap = startsWithCapital(word)
  if (startsWithCap) {
    const letterArray: boolean[] = []
    word.split('').map((letter, idx) => {
      if (idx === 0) {
        // Pretend that the first letter isn't capital
        letterArray[idx] = false
      } else {
        letterArray[idx] = startsWithCapital(letter)
      }
    })
    const anyCap = letterArray.some((e) =>
      e === true)

    if (anyCap) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

export function isWordExcluded(word: string) {
  const excludedWords = [
    // English
    'a',
    'is',
    'be',
    'of',
    'so',
    'do',
    'to',
    'in',
    'and',
    'the',
    'was',
    'for',

    // Dutch
    'de',
    'en',
    'een',
    'het',
    'met',
    'dan',
    'van',
    'zal',
    'zou',
    'voor',
    'zijn',
    'daar',
    'hier',
    'toen',
  ]
  return includes(excludedWords, word.toLowerCase())
}

export function getTagsFromText(text: string): string[] {
  // TODO: Also get tags from tld, hash and query
  const tags: string[] = []
  const split = text.split(' ')
  // TODO: Also make heuristic compound-words from each even and uneven word-pairs, perhaps even with three words at the very end of the list? BUT make seperate function for exclusive use in autocomplete
  split.forEach((word: string) => {
    if (checkTagCompatible().test(word) && !isWordExcluded(word)) {
      for (const char of FORBIDDEN_CHARS) {
        word = word.split(char).join('')
      }
      // Any word that only starts with a capital letter gets lowercased
      word = startsWithCapitalOnly(word) ? word.toLowerCase() : word
      tags.push(word)
    }
  })
  return tags
}

// Compromise.js
/* eslint @typescript-eslint/no-unsafe-member-access: 0 */
/* eslint @typescript-eslint/no-unsafe-call: 0 */

// export function getNouns(sentence: string) {
//   const doc = nlp(sentence)
//   const nouns = doc.match('#Noun')
//   const nounsArray = nouns.map(d => {
//     return d.text('reduced') //return strings instead
//   })
//   return nounsArray
// }

// export function getAdjectives(sentence: string) {
//   const doc = nlp(sentence)
//   const adj = doc.nouns().adjectives().out('array')
//   return adj
// }

// export function getTerms(sentence: string) {
//   const doc = nlp(sentence)
//   const terms = doc.terms()
//   const termsArray = terms.map(d => {
//     return d.text('reduced') //return strings instead
//   })
//   return termsArray
// }

// export function getConjunctions(sentence: string) {
//   const doc = nlp(sentence)
//   const con = doc.match('#Conjunction').text()
//   // @ts-ignore because it exists
//   const left = doc.match(con).lookBehind('#Noun$').text()
//   // @ts-ignore because it exists
//   const right = doc.match(con).lookAhead('^#Noun').text()
//   return [left, right]
// }

// export function getValues(sentence: string) {
//   const doc = nlp(sentence)
//   // @ts-ignore because it exists
//   const nums = doc.values()
//   return nums.out('array')
// }

// export function toNumbers(sentence: string) {
//   const doc = nlp(sentence)
//   doc.numbers().toNumber()
//   return doc.text()
// }

// export function getPeople(sentence: string) {
//   const doc = nlp(sentence)
//   const ppl = doc.people()
//   return ppl.text()
// }
