export type PostCats =
  | 'media'
  | 'people'
  | 'humans'
  | 'health'
  | 'philosophy'
  | 'wisdom'
  | 'journalism'
  | 'globalism'
  | 'history'
  | 'politics'
  | 'weather'
  | 'finance'
  | 'nature'
  | 'technology'
  | 'robotics'
  | 'electronics'
  | 'science'
  | 'film'
  | 'life'
  | 'food'
  | 'investigation'
  | 'opinion'
  | 'trope'
  | 'meme'
  | 'comedy'
  | 'organisation'
  | 'art'
  | 'literature'
  | 'blog'
  | 'story'
  | 'timeline'
  | 'community'
  | 'gaming'
  | 'software'
  | 'place'
  | 'music'
  | 'symbology'
  | 'conspiracy'
  | 'secret'
  | 'mystery'
  | 'supernatural'
  | 'travel'
  | 'animal'
  | 'national'
  | 'engineering'
  | 'energy'
  | 'satire'
  | 'miscellaneous'

export type SysCats = 'relation' | 'video' | 'playlist' | 'link'

export type Cats = PostCats | SysCats

export interface CatData {
  val: Cats
  icon: string
  alias?: string
  modifier?: string
  disabled?: boolean
}

const categories: CatData[] = [
  {
    val: 'media',
    icon: 'tv',
  },
  {
    val: 'people',
    icon: 'supervisor_account',
  },
  {
    val: 'humans',
    alias: 'humanity',
    modifier: 'human',
    icon: 'pan_tool',
  },
  {
    val: 'health',
    // modifier: 'healthy',
    icon: 'local_hospital',
  },

  {
    val: 'philosophy',
    modifier: 'philosophical',
    icon: 'school',
  },
  {
    val: 'wisdom',
    icon: 'spa',
  },
  {
    val: 'journalism',
    alias: 'news',
    modifier: 'journalist',
    icon: 'public',
  },
  {
    val: 'globalism',
    icon: 'language',
    modifier: 'globalist',
  },

  {
    val: 'history',
    modifier: 'historic',
    icon: 'account_balance',
  },
  {
    val: 'politics',
    icon: 'do_not_step',
    modifier: 'political',
  },
  {
    val: 'weather',
    alias: 'climate',
    icon: 'storm',
  },
  {
    val: 'finance',
    alias: 'money',
    modifier: 'financial',
    icon: 'payments',
  },

  {
    val: 'nature',
    // modifier: 'natural',
    icon: 'forest',
  },
  {
    val: 'technology',
    icon: 'nfc',
    // modifier: 'technological',
  },
  {
    val: 'robotics',
    modifier: 'robotic',
    icon: 'precision_manufacturing',
  },
  {
    val: 'electronics',
    alias: 'hardware',
    modifier: 'electronic',
    icon: 'mouse',
  },

  {
    val: 'science',
    alias: 'knowledge',
    modifier: 'scientific',
    icon: 'biotech ',
  },
  {
    val: 'film',
    alias: 'movies',
    icon: 'theaters',
  },
  {
    val: 'life',
    icon: 'whatshot',
  },
  {
    val: 'food',
    icon: 'local_pizza',
  },

  {
    val: 'investigation',
    alias: 'investigations',
    modifier: 'investigational',
    icon: 'fingerprint',
  },
  {
    val: 'opinion',
    alias: 'subjective',
    modifier: 'opinionated',
    icon: 'record_voice_over',
  },
  {
    val: 'trope',
    alias: 'tropes',
    icon: 'history_edu',
  },
  {
    val: 'meme',
    alias: 'memes',
    modifier: 'memetic',
    icon: 'tips_and_updates',
  },

  {
    val: 'comedy',
    modifier: 'comedic',
    icon: 'mood',
  },
  {
    val: 'organisation',
    alias: 'organisations',
    modifier: 'organisational',
    icon: 'business_center',
  },
  {
    val: 'art',
    modifier: 'artistic',
    icon: 'palette',
  },
  {
    val: 'literature',
    icon: 'local_library',
  },

  {
    val: 'blog',
    alias: 'blogs',
    icon: 'rss_feed',
  },
  {
    val: 'story',
    alias: 'stories',
    icon: 'menu_book',
  },

  {
    val: 'timeline',
    icon: 'view_timeline',
  },
  {
    val: 'community',
    alias: 'communities',
    icon: 'diversity_2',
    // icon: 'deck'
  },

  {
    val: 'gaming',
    icon: 'sports_esports',
    // icon: 'videogame_asset'
  },
  {
    val: 'software',
    alias: 'apps',
    icon: 'terminal',
  },
  {
    val: 'place',
    alias: 'places',
    modifier: 'physical', // icw location? :S
    icon: 'place',
  },
  {
    val: 'music',
    icon: 'music_note',
    modifier: 'musical',
    // icon: 'audiotrack'
  },

  {
    val: 'symbology',
    alias: 'logos',
    modifier: 'symbolic',
    icon: 'shield',
  },
  {
    val: 'conspiracy',
    alias: 'what',
    modifier: 'conspiratorial',
    icon: 'coronavirus',
  },
  {
    val: 'secret',
    alias: 'secrets',
    modifier: 'secretive',
    icon: 'vpn_key',
  },
  {
    val: 'mystery',
    alias: 'mysteries',
    modifier: 'mysterious',
    icon: 'meeting_room',
  },

  {
    val: 'supernatural',
    icon: 'auto_awesome',
  },
  {
    val: 'travel',
    modifier: 'traveling',
    icon: 'explore',
  },
  {
    val: 'animal',
    alias: 'animals',
    modifier: 'animalistic',
    icon: 'pets',
  },
  {
    val: 'national',
    icon: 'flag',
  },

  {
    val: 'engineering',
    icon: 'engineering',
  },
  {
    val: 'energy',
    alias: 'power',
    modifier: 'energic',
    icon: 'bolt',
  },
  {
    val: 'satire',
    modifier: 'satirical',
    icon: 'child_care',
  },
  {
    val: 'miscellaneous',
    alias: 'misc',
    icon: 'workspaces',
  },
] as const

// Non-user choosable categories
export const sysCats: CatData[] = [
  {
    val: 'relation',
    icon: 'swipe_right_alt',
  },
  {
    val: 'video',
    icon: 'play_circle',
  },
  {
    val: 'playlist',
    icon: 'playlist_play',
  },
  {
    val: 'link',
    icon: 'link',
  },
]

export const allCats = [...categories, ...sysCats]

export const allCatsArray: Cats[] = allCats.map((cat) =>
  cat.val)

export const allSysCatsArray: Cats[] = sysCats.map((cat) =>
  cat.val)

export default categories
