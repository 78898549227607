import { lazy, StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'

import { CookieConsent } from '@Components/ui/CookieConsent'

import printBuildInfo from '@Core/utils/printBuildInfo'

import { FullscreenLoading } from '@Routes/system/FullscreenLoading'

const App = lazy(() =>
  import('./App'))
const ProviderWrapper = lazy(() =>
  import('./ProviderWrapper')) // Wrapped so we can Lazyload


printBuildInfo()
const container = document.getElementById('root') as Element
const root = createRoot(container)

root.render((
  <StrictMode>
    <Suspense fallback={<FullscreenLoading />}>
      <ProviderWrapper>
        <App />
        <CookieConsent />
      </ProviderWrapper>
    </Suspense>
  </StrictMode>
))

// YT API
// handleClientLoad()
